var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dev-onboard" },
    [
      _c("Navbar"),
      _c("main", [
        _c("div", { staticClass: "container-wrapper" }, [
          _c("div", { staticClass: "top-container" }, [
            _c(
              "div",
              {
                staticStyle: { width: "30%", cursor: "pointer" },
                on: { click: _vm.handleGoBack },
              },
              [
                _c("img", {
                  staticStyle: { "margin-right": "9px" },
                  attrs: { src: "/img/onboarding/back-icon.svg" },
                }),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#0055a5",
                      "font-size": "14px",
                      "font-family": "Moderat",
                      "font-style": "normal",
                      "font-weight": "500",
                    },
                  },
                  [_vm._v("Go Back")]
                ),
              ]
            ),
            _vm._m(0),
          ]),
          _c("div", { staticClass: "page-content-div" }, [
            _c("div", { staticClass: "center-container" }, [
              _c("div", { staticClass: "content-title" }, [
                _vm._v("Create a Team or Just Explore"),
              ]),
              _c("div", { staticClass: "sub-title" }, [
                _vm._v("How do you want to experience Grupa?"),
              ]),
              _c(
                "div",
                { staticClass: "card-container" },
                _vm._l(_vm.options, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "option-card",
                      on: {
                        click: function ($event) {
                          return _vm.handleClick(item.id)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "left-content" }, [
                        _c("div", { staticClass: "card-name" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(item.name) +
                              "\n                "
                          ),
                        ]),
                        _c("div", { staticClass: "card-content" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(item.content) +
                              "\n                "
                          ),
                        ]),
                      ]),
                      _vm._m(1, true),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "progress indicator_progress" }, [
      _c("a", { staticClass: "indicator dark" }, [_c("div")]),
      _c("a", { staticClass: "indicator dark" }, [_c("div")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right-content" }, [
      _c("img", { attrs: { src: "/img/onboarding/right-arrow.svg", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }